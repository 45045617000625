import { ref, unref, Ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { Asset, DecoratedAsset, MaybeRef } from "@/types";
import { decorateAsset } from "@/config/asset";
import { isBlank } from "@/utils/string";

export interface UseAssetOptions {
  cache?: MaybeRef<boolean>;
  enabled?: MaybeRef<boolean>;
}

export interface UseAssetResult {
  asset: Ref<Asset | undefined>;
  decoratedAsset: Ref<DecoratedAsset | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useAsset(assetUuid: string, { cache = false, enabled = true }: UseAssetOptions = {}): UseAssetResult {
  const asset: Ref<Asset | undefined> = ref();
  const decoratedAsset: Ref<DecoratedAsset | undefined> = ref();
  const loadingError = ref(false);

  if (isBlank(assetUuid)) {
    // empty assetUuid, return empty result
    return {
      asset,
      decoratedAsset,
      loadingError,
      refetch: () => {
        /**/
      },
      loading: ref(false)
    };
  }

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query AssetQuery($assetUuid: ID!) {
        asset: asset(assetUuid: $assetUuid) {
          assetUuid
          knownAssetUuid
          assetCategory {
            name
          }
          assetType {
            name
          }
          manufacturer {
            name
          }
          assetModel {
            name
          }
          smart
          assetCategoryUuid
          assetTypeUuid
          manufacturerUuid
          assetModelUuid
          name
          serialNumber
          floorX
          floorY
        }
      }
    `,
    { assetUuid: assetUuid },
    () => ({
      ...(unref(cache) ? { fetchPolicy: "no-cache", notifyOnNetworkStatusChange: true } : {}),
      enabled: unref(enabled)
    })
  );

  onError(() => {
    loadingError.value = true;
  });
  onResult(queryResult => {
    if (queryResult.data) {
      asset.value = queryResult.data.asset;
      decoratedAsset.value = decorateAsset(queryResult.data.asset);
    }
  });

  return { asset, decoratedAsset, loadingError, refetch, loading };
}
