import { mergeAssetConfig } from "@/config/asset-config-reader";
import baseDeviceConfig from "@/config/base-device";

const config = mergeAssetConfig(baseDeviceConfig, {
  i18nNamespace: "gateway",
  components: {
    AssetDashboardTab: () => import("@/gateway/components/DeviceDashboardTab.vue"),
    AssetInspectorStatus: {
      component: () => import("@/components/AssetInspectorStatus.vue"),
      props: {
        itemProperties: ["offline", "current_network", "lte_rssi"]
      }
    }
  },
  categoryProperties: {
    rssi: { property: "lte_rssi" }
  },
  properties: {
    firmware: {
      dataType: "string"
    },
    gateway_timestamp: {
      dataType: "string"
    },
    connection_type: {
      dataType: "string"
    },
    current_network: {
      dataType: "string",
      graphPointConfig: {
        "T-Mobile": { y: 100, color: "#DD00A9" }
      }
    },
    lte_rssi: {
      dataType: "number",
      unit: "db"
    },
    lte_rsrq: {
      dataType: "number"
    },
    lte_rsrp: {
      dataType: "number"
    }
  }
});

export default config;
