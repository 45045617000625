import { computed, ref, Ref, unref } from "vue";
import { useQuery, useSubscription } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { DecoratedAsset, GqlAsset, MaybeRef } from "@/types";
import { decorateAsset, updateAssetProperties } from "@/config/asset";
import store from "@/store";
import { deviceLock } from "@/gql/fragments/deviceLock";
import { stateChange } from "@/gql/fragments/state-change";

type PropertyCategories = Record<string, boolean | null | undefined>;

export interface UseBuildingAssetsOptions {
  includeFloorAssets?: MaybeRef<boolean>;
  includeAssetHealth?: MaybeRef<boolean>;
  includePropertyCategories?: MaybeRef<PropertyCategories>;
  onlyKnownAssets?: MaybeRef<boolean>;
  requiredProperties?: MaybeRef<string[]>;
  knownAssetUuids?: MaybeRef<string[]>;
  startDate?: MaybeRef<DateTime | null | undefined>;
  endDate?: MaybeRef<DateTime | null | undefined>;
  enabled?: MaybeRef<boolean>;
  subscribe?: boolean;
}

export interface UseBuildingAssetsResult {
  assets: Ref<DecoratedAsset[] | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useBuildingAssets(
  buildingUuid: MaybeRef<string | null>,
  {
    includeFloorAssets = false,
    includeAssetHealth = false,
    includePropertyCategories = {},
    requiredProperties = [],
    onlyKnownAssets = false,
    knownAssetUuids = [],
    startDate = DateTime.fromMillis(0),
    endDate = DateTime.fromMillis(0),
    enabled = true,
    subscribe = false
  }: UseBuildingAssetsOptions = {}
): UseBuildingAssetsResult {
  const assets = ref<DecoratedAsset[] | undefined>();
  const loadingError = ref(false);

  const {
    properties: includeProperties = false,
    settings: includeSettings = false,
    miscFields: includeMiscFields = false
  } = unref(includePropertyCategories);

  const params = computed(() => {
    let startDateVal = unref(startDate);
    let endDateVal = unref(endDate);
    if (!unref(buildingUuid) || !startDateVal || !endDateVal) return null;

    if (startDateVal > endDateVal) [startDateVal, endDateVal] = [endDateVal, startDateVal];

    return {
      buildingUuid: unref(buildingUuid),
      includeFloorAssets: unref(includeFloorAssets),
      includeAssetHealth: unref(includeAssetHealth),
      includeProperties: unref(includeProperties),
      includeSettings: unref(includeSettings),
      includeMiscFields: unref(includeMiscFields),
      requiredProperties: unref(requiredProperties),
      startDate: startDateVal.setZone("utc"),
      endDate: endDateVal.endOf("day").setZone("utc"),
      timestamp: DateTime.local() // Ignored, but ensures that variables are never the same
    };
  });

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      ${deviceLock}
      query BuildingAssetsQuery(
        $buildingUuid: ID!
        $includeFloorAssets: Boolean!
        $includeAssetHealth: Boolean!
        $includeProperties: Boolean!
        $includeSettings: Boolean!
        $includeMiscFields: Boolean!
        $requiredProperties: [String!]!
        $startDate: DateTime!
        $endDate: DateTime!
      ) {
        assets: buildingAssets(
          buildingUuid: $buildingUuid
          includeFloorAssets: $includeFloorAssets
          requiredProperties: $requiredProperties
        ) {
          assetUuid
          assetCategory {
            name
          }
          assetType {
            name
          }
          manufacturer {
            name
          }
          assetModel {
            name
          }
          floor {
            position
            name
          }
          name
          knownAssetUuid
          assetCategoryUuid
          assetTypeUuid
          manufacturerUuid
          assetModelUuid
          installationDate
          availableProperties
          smart
          serialNumber
          floorUuid
          floorX
          floorY
          assetHealth(startDate: $startDate, endDate: $endDate) @include(if: $includeAssetHealth) {
            normalTimePct
            propertyName
          }
          ... on Device {
            devEui
            properties @include(if: $includeProperties)
            settings @include(if: $includeSettings)
            miscFields @include(if: $includeMiscFields)
            thresholds {
              values
            }
            ...DeviceLockFields
          }
        }
      }
    `,
    params,
    () => ({
      enabled: unref(enabled) && params.value !== null,
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      let gqlAssets: GqlAsset[] = queryResult.data.assets;

      if (unref(onlyKnownAssets)) {
        gqlAssets = gqlAssets.filter(a => a.knownAssetUuid);
      }

      const knownAssetUuidsValue = unref(knownAssetUuids);
      if (knownAssetUuidsValue.length > 0) {
        gqlAssets = gqlAssets.filter(a => knownAssetUuidsValue.includes(a.knownAssetUuid));
      }

      assets.value = gqlAssets.map(asset => decorateAsset(asset));
    }
  });

  if (subscribe) subscribeToAssetChanges(assets);
  return { assets, loadingError, refetch, loading };
}

function subscribeToAssetChanges(assets: Ref<DecoratedAsset[] | undefined>): void {
  const assetUuids = computed(() => assets.value?.map(a => a.assetUuid) ?? []);

  const { onResult: onSubscriptionResult } = useSubscription(
    gql`
      ${stateChange}
      subscription AssetDataChanges($assetUuids: [ID!]!, $authorization: String!) {
        assetDataChanges(assetUuids: $assetUuids, authorization: $authorization) {
          assetUuid
          property
          stamp
          value
          ...StateChangeFields
        }
      }
    `,
    {
      assetUuids,
      authorization: store.getters.accessToken
    },
    () => ({
      enabled: assetUuids.value.length > 0
    })
  );

  onSubscriptionResult(({ data }) => {
    if (assets.value && data?.assetDataChanges) {
      updateAssetProperties(assets.value, data.assetDataChanges);
    }
  });
}
