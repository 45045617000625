import { NavigationGuardNext, Route } from "vue-router";
import { getInstance } from "@/plugins/auth0";
import store from "@/store";

export const setAccountGuard = (to: Route, from: Route, next: NavigationGuardNext): void => {
  const accountUuid = to.query["account_uuid"];
  if (!accountUuid) return next();

  const authService = getInstance();

  const guardAction = () => {
    store.commit("setAccountUuid", accountUuid);
    delete to.query["account_uuid"];
    return next({ path: to.path, query: to.query, replace: true });
  };

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.isLoading) {
    return guardAction();
  }

  authService.$watch("isLoading", (isLoading: boolean) => {
    if (isLoading === false) {
      return guardAction();
    }
  });
};
