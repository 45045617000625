import { Route } from "vue-router";
import { getInstance } from "@/plugins/auth0";

export const signupRedirectGuard = (to: Route): void => {
  const authService = getInstance();

  const guardAction = () => {
    authService.loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
        login_hint: to.query["email"]
      },
      appState: { targetPath: "/" }
    });
  };

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.isLoading) {
    return guardAction();
  }

  authService.$watch("isLoading", (isLoading: boolean) => {
    if (isLoading === false) {
      return guardAction();
    }
  });
};
