import { AssetConfig } from "@/types";
import { isBlank } from "@/utils/string";
import { readAssetConfig } from "./asset-config-reader";

const config: AssetConfig = readAssetConfig({
  i18nNamespace: "asset",
  components: {
    AssetViewContents: () => import("@/components/AssetViewContents.vue"),
    AssetHeader: () => import("@/components/AssetHeader.vue"),
    AssetDashboardTab: () => import("@/components/AssetDashboardTab.vue"),
    AssetDocumentsTab: () => import("@/components/AssetDocumentsTab.vue"),
    AssetAlertsTab: () => import("@/components/AssetAlertsTab.vue"),
    AssetJournalTab: () => import("@/components/AssetJournalTab.vue")
  },
  fields: {
    name: {
      dataType: "string",
      i18nNamespace: "asset",
      rules: { required: true }
    },
    assetCategoryUuid: {
      dataType: "string",
      displayType: "select",
      i18nNamespace: "asset",
      disabled: ({ model }) => !isBlank(model.knownAssetUuid)
    },
    assetTypeUuid: {
      dataType: "string",
      displayType: "select",
      i18nNamespace: "asset",
      disabled: ({ model }) => isBlank(model.assetCategoryUuid) || !isBlank(model.knownAssetUuid)
    },
    manufacturer: {
      dataType: "string",
      displayType: "combobox",
      i18nNamespace: "asset",
      disabled: ({ model }) => !isBlank(model.knownAssetUuid)
    },
    model: {
      dataType: "string",
      displayType: "combobox",
      i18nNamespace: "asset",
      disabled: ({ model }) =>
        (isBlank(model.manufacturer.id) && isBlank(model.manufacturer.text)) || !isBlank(model.knownAssetUuid)
    },
    serialNumber: {
      dataType: "string",
      i18nNamespace: "asset"
    },
    installationDate: {
      dataType: "date",
      i18nNamespace: "asset"
    }
  },
  properties: {
    offline: {
      dataType: "number",
      defaultValue: 3,
      options: [0, 1, 2, 3]
    },
    ubx_error_codes: {
      dataType: "string",
      category: "miscFields"
    },
    best_gateway_eui: {
      dataType: "string",
      category: "miscFields"
    },
    rssi: {
      dataType: "number",
      category: "miscFields"
    },
    battery: {
      dataType: "number",
      category: "miscFields"
    },
    firmware_version: {
      dataType: "string",
      category: "settings"
    }
  }
});

export default config;
